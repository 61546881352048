import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { shallow } from 'zustand/shallow'
import CopyWindow from '../../components/CopyWindow'
import Pagination from '../../components/Pagination'
import Title from '../../components/Title'
import { AutoColumn, Flex } from '../../components/styled'
import { MANAGE_USERS_ACCOUNT } from '../../constants/roles'
import { useCopy } from '../../hooks/useCopy'
import { useWallet } from '../../hooks/useWallet'
import { useUserStore } from '../../store/userStore'
import { InfoRow, TitleRow } from './components/rows'

const UsersSection = styled.section`
    width: 100%;
    background-color: ${({ theme }) => theme.white};
    border-radius: 10px;
`

const Bottom = styled(Flex)`
    width: 100%;
    justify-content: flex-end;
`

const LIMIT = 12

const UserManagement = () => {
    const { getAllWallets } = useWallet()
    const { user } = useUserStore(({ user }) => ({ user }), shallow)
    const hasRole = user.roles?.find(
        (role) => role.name === MANAGE_USERS_ACCOUNT
    )

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [walletList, setWalletList] = useState([])

    const { isCopied, closeCopyWindow, openCopyWindow } = useCopy()
    const {
        isCopied: isSuccess,
        closeCopyWindow: closeInfoWindow,
        openCopyWindow: openInfoWindow,
    } = useCopy()

    const skip = page === 1 ? 0 : page * LIMIT - LIMIT

    const getWallets = async () => {
        const response = await getAllWallets({ limit: LIMIT, skip })
        setTotalPages(response?.totalPages)
        setWalletList(response?.data)
    }

    useEffect(() => {
        getWallets()
    }, [page])

    return (
        <>
            {hasRole === undefined && (
                <Title>You do not have rights to manage Users Account.</Title>
            )}
            {isCopied && <CopyWindow onClose={closeCopyWindow} />}
            {isSuccess && (
                <CopyWindow text="Success" onClose={closeInfoWindow} />
            )}
            {hasRole !== undefined && (
                <AutoColumn gap="lg">
                    <Title>User Management</Title>
                    <UsersSection>
                        <TitleRow />
                        {walletList?.length !== 0 &&
                            walletList?.map((wallet) => (
                                <InfoRow
                                    key={wallet.id}
                                    wallet={wallet}
                                    openInfoWindow={openInfoWindow}
                                    openCopyWindow={openCopyWindow}
                                />
                            ))}
                    </UsersSection>
                    <Bottom>
                        <Pagination
                            setPage={setPage}
                            page={page}
                            totalPages={totalPages}
                        />
                    </Bottom>
                </AutoColumn>
            )}
        </>
    )
}

export default UserManagement
