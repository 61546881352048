import React from 'react'
import styled from 'styled-components'
import {
    FlexAlign,
    RowBetween,
    StyledSpan,
} from '../../../../components/styled'
import DoubleToken from '../../../../components/DoubleToken'
import { formatCommission } from '../../../../utils/formatCommission'
import { sumFloats } from '../../../../utils/sumFloats'

const StyledTitle = styled(RowBetween)`
    padding: 6px 0;
`

const StyledInfo = styled(StyledTitle)`
    border-top: 1px solid ${({ theme }) => theme.border1};
    padding: 16px 0;
`

const TitleText = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.textPrimary};
    font-weight: 600;
    line-height: 20.4px;
    text-align: center;
    flex-grow: 1;
`

const LeftText = styled(TitleText)`
    text-align: center;
    max-width: 200px;
`

const RightText = styled(TitleText)`
    max-width: 150px;
`

const InfoText = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.textPrimary};
    font-weight: 500;
    line-height: 20.4px;
    flex-grow: 1;
    text-align: center;
`

const RightInfoText = styled(InfoText)`
    max-width: 150px;
`

const CenteredSpan = styled(StyledSpan)`
    text-align: center;
`
const Block = styled(FlexAlign)`
    justify-content: center;
    max-width: 200px;
    min-width: 200px;
    flex-grow: 1;
`

export const TitleRow = () => {
    return (
        <StyledTitle>
            <LeftText>
                <StyledSpan w="160px">Pairs</StyledSpan>
            </LeftText>
            <TitleText>
                <StyledSpan w="80px">Volume</StyledSpan>
            </TitleText>
            <TitleText>
                <StyledSpan w="100px">Total number of transactions</StyledSpan>
            </TitleText>
            <TitleText>
                <StyledSpan w="100px">Total comission amount</StyledSpan>
            </TitleText>
            <TitleText>
                <StyledSpan w="100px">Total comission distrbuted</StyledSpan>
            </TitleText>
            <RightText>Chief Finance profit</RightText>
        </StyledTitle>
    )
}

export const InfoRow = ({ data }) => {
    const {
        token0,
        token1,
        volumeUSD,
        swapsAmount,
        totalSupply,
        bidelityProfit,
    } = data

    const commissionDistributed = formatCommission(totalSupply)
    const profit = formatCommission(bidelityProfit)
    const totalCommission = sumFloats(profit, commissionDistributed)
    const formattedVolume = formatCommission(volumeUSD)

    return (
        <StyledInfo>
            <Block>
                <DoubleToken token0={token0} token1={token1} />
            </Block>
            <InfoText>
                <CenteredSpan w="80px">{formattedVolume} USD</CenteredSpan>
            </InfoText>
            <InfoText>
                <CenteredSpan w="100px">{swapsAmount}</CenteredSpan>
            </InfoText>
            <InfoText>
                <StyledSpan w="140px">{totalCommission} CFC</StyledSpan>
            </InfoText>
            <InfoText>
                <StyledSpan w="100px">{commissionDistributed} CFC</StyledSpan>
            </InfoText>
            <RightInfoText>{profit} CFC</RightInfoText>
        </StyledInfo>
    )
}
