import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { AutoColumn } from '../../../../components/styled'
import { Bottom, InfoSection } from '../../../../components/StyledSection'
import Pagination from '../../../../components/Pagination'
import { POOL_GENERAL_QUERY } from '../../query'
import { usePagination } from '../../../../hooks/usePagination'
import { PAGINATION_LIMIT } from '../../../../constants/pages'
import { GeneralHeaderRow, GeneralInfoRow } from './general-rows'

const General = () => {
    const { data, loading } = useQuery(POOL_GENERAL_QUERY)
    const [isAnyLocked, setIsAnyLocked] = useState(false)

    const { renderList, totalPages, page, changePage } = usePagination(
        data?.pairs,
        loading,
        PAGINATION_LIMIT
    )

    return (
        <AutoColumn gap="xl">
            <InfoSection>
                <GeneralHeaderRow isAnyLocked={isAnyLocked} />
                {!loading &&
                    renderList?.length !== 0 &&
                    renderList?.map((pair, index) => (
                        <GeneralInfoRow
                            key={pair.id}
                            data={pair}
                            index={index + 1}
                            setIsAnyLocked={setIsAnyLocked}
                            isAnyLocked={isAnyLocked}
                        />
                    ))}
            </InfoSection>
            <Bottom>
                <Pagination
                    page={page}
                    setPage={changePage}
                    totalPages={totalPages}
                />
            </Bottom>
        </AutoColumn>
    )
}

export default General
