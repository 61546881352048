import { Contract } from '@ethersproject/contracts'
import { ethers } from 'ethers'
import { BIDE_ABI } from './bidelity.js'
import TOKEN_ABI from './token.json'

const BLID_ADDRESS = '0xC696A459d461467ffeCFC8F6953e41EDB7D44f41'

export function getContract(address, ABI) {
    return new Contract(
        address,
        ABI,
        ethers.getDefaultProvider(
            'https://goerli.infura.io/v3/c558761fd1204a879c54d5187f0ef53f'
        )
    )
}

export function getBidlityContract() {
    return getContract(BLID_ADDRESS, BIDE_ABI)
}

export async function getCurrency(address) {
    const token = getContract(address, TOKEN_ABI)
    const symbol = await token.symbol()
    return { symbol, id: address, name: symbol }
}
