import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import './index.css'

export const apolloClient = new ApolloClient({
    uri: 'https://api.thegraph.com/subgraphs/name/alexsandrbarabash/test-2',
    cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <>
        <FixedGlobalStyle />
        <ThemeProvider>
            <ThemedGlobalStyle />
            <div id="portal" />
            <ApolloProvider client={apolloClient}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ApolloProvider>
        </ThemeProvider>
    </>
)
