import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { shallow } from 'zustand/shallow'
import PlusIcon from '../../../assets/svg/plus.svg'
import { Button } from '../../../components/Button'
import CopyWindow from '../../../components/CopyWindow'
import Pagination from '../../../components/Pagination'
import { Bottom, InfoSection } from '../../../components/StyledSection'
import { Tab } from '../../../components/Tab'
import Title from '../../../components/Title'
import { AutoColumn, Icon, RowBetween } from '../../../components/styled'
import { MANAGE_ADMINS } from '../../../constants/roles'
import { useCopy } from '../../../hooks/useCopy'
import { useUser } from '../../../hooks/useUser'
import { useUserStore } from '../../../store/userStore'
import { TEXT } from '../../../theme'
import RolesForm from '../common/RolesForm'
import { RolesHeaderRow, RolesInfoRow } from './components/roles-rows'

const RolesInfoSection = styled(InfoSection)`
    border-radius: 10px;
`

const AddButton = styled(Button)`
    padding-bottom: 8.5px;
    padding-top: 8.5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 23.1px;
    border-radius: 10px;
`

const Plus = styled(Icon)`
    margin-left: 8px;
`

const LIMIT = 8

const AdministrativeRoles = () => {
    const { getAllUsers } = useUser()
    const { user } = useUserStore(({ user }) => ({ user }), shallow)
    const hasRole = user.roles.find((role) => role.name === MANAGE_ADMINS)
    const navigate = useNavigate()

    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [usersList, setUsersList] = useState([])

    const navigateToCreatePage = () => navigate('/roles/create')

    const skip = page === 1 ? 0 : page * LIMIT - LIMIT

    const getUsers = async () => {
        const response = await getAllUsers({ limit: LIMIT, skip })
        setTotalPages(response?.totalPages)
        setUsersList(response?.data)
    }

    useEffect(() => {
        getUsers()
    }, [page])

    const {
        isCopied: isSuccess,
        closeCopyWindow: closeInfoWindow,
        openCopyWindow: openInfoWindow,
    } = useCopy()

    const {
        isCopied: isInfoChanged,
        closeCopyWindow: closeInfoChangedWindow,
        openCopyWindow: openInfoChangedWindow,
    } = useCopy()

    const {
        isCopied: isUserDeleted,
        closeCopyWindow: closeUserDeletedModal,
        openCopyWindow: openUserDeletedModal,
    } = useCopy()

    const onSuccess = useCallback(() => {
        openUserDeletedModal()
        getUsers()
    }, [])

    return (
        <>
            {hasRole === undefined && (
                <Title>You do not have rights to manage Admins.</Title>
            )}
            {isUserDeleted && (
                <CopyWindow
                    text="Admin Deleted"
                    onClose={closeUserDeletedModal}
                />
            )}
            {isSuccess && (
                <CopyWindow text="Password changed" onClose={closeInfoWindow} />
            )}
            {isInfoChanged && (
                <CopyWindow
                    text="Information updated"
                    onClose={closeInfoChangedWindow}
                />
            )}
            {hasRole !== undefined && (
                <AutoColumn gap="lg">
                    <RowBetween>
                        <Title>Administrative Roles</Title>
                        <AddButton onClick={navigateToCreatePage}>
                            <TEXT.white>Add New Admin</TEXT.white>
                            <Plus>
                                <img src={PlusIcon} alt="" />
                            </Plus>
                        </AddButton>
                    </RowBetween>
                    <div>
                        <Tab active={'true'}>Change Password</Tab>
                        <RolesForm
                            openInfoWindow={openInfoWindow}
                            openInfoChangedWindow={openInfoChangedWindow}
                        />
                    </div>
                    {usersList?.length !== 0 && (
                        <AutoColumn gap="xl">
                            <TEXT.primary fontSize={24} fontWeight={600}>
                                List of admin’s
                            </TEXT.primary>
                            <RolesInfoSection>
                                <RolesHeaderRow />
                                {usersList?.map((admin, index) => (
                                    <RolesInfoRow
                                        key={admin.id}
                                        data={admin}
                                        number={index + 1}
                                        onSuccess={onSuccess}
                                    />
                                ))}
                            </RolesInfoSection>
                            <Bottom>
                                <Pagination
                                    setPage={setPage}
                                    page={page}
                                    totalPages={totalPages}
                                />
                            </Bottom>
                        </AutoColumn>
                    )}
                </AutoColumn>
            )}
        </>
    )
}

export default AdministrativeRoles
