import axios from 'axios'
import { apiUrls } from '../constants'

const baseUrl = process.env.REACT_APP_BASE_URL

const $client = axios.create({
    baseURL: baseUrl,
})

$client.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    config.headers['x-refresh-token'] = localStorage.getItem('refresh-token')
    return config
})

const $refreshClient = axios.create({
    baseURL: baseUrl,
})

$refreshClient.defaults.headers.post[
    'x-refresh-token'
] = `${localStorage.getItem('refresh-token')}`

$client.interceptors.response.use(
    (config) => config,
    async (error) => {
        const originalRequest = error.config

        if (error?.response?.status === 401) {
            try {
                const response = await $refreshClient.post(apiUrls.auth.refresh)
                localStorage.setItem('token', response?.data?.accessToken)
                localStorage.setItem(
                    'refresh-token',
                    response?.data?.refreshToken
                )
                return $client.request(originalRequest)
            } catch (error) {
                localStorage.clear()
                window.location.href = '/'
                window.location.reload()
            }
        } else {
            throw error
        }
    }
)

export default $client
