import React from 'react'
import { useQuery } from '@apollo/client'
import Pagination from '../../../../components/Pagination'
import { AutoColumn } from '../../../../components/styled'
import { Bottom, InfoSection } from '../../../../components/StyledSection'
import { TRANSACTIONS_INFO_QUERY } from '../../query'
import { usePagination } from '../../../../hooks/usePagination'
import { PAGINATION_LIMIT_TWELVE } from '../../../../constants/pages'
import { TransactionsHeader, TransactionsInfoRow } from './transactions-rows'

const Transactions = () => {
    const { data, loading } = useQuery(TRANSACTIONS_INFO_QUERY)
    const { renderList, totalPages, page, changePage } = usePagination(
        data?.swaps,
        loading,
        PAGINATION_LIMIT_TWELVE
    )
    return (
        <AutoColumn gap="xl">
            <InfoSection>
                <TransactionsHeader />
                {!loading &&
                    renderList?.length !== 0 &&
                    renderList?.map((tx, index) => (
                        <TransactionsInfoRow
                            key={tx.id}
                            data={tx}
                            index={index + 1}
                        />
                    ))}
            </InfoSection>
            <Bottom>
                <Pagination
                    page={page}
                    setPage={changePage}
                    totalPages={totalPages}
                />
            </Bottom>
        </AutoColumn>
    )
}

export default Transactions
