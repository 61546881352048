import React from 'react'
import { useQuery } from '@apollo/client'
import { AutoColumn } from '../../../../components/styled'
import { Bottom, InfoSection } from '../../../../components/StyledSection'
import Pagination from '../../../../components/Pagination'
import { POOL_TRANSACTIONS_QUERY } from '../../query'
import { usePagination } from '../../../../hooks/usePagination'
import { PAGINATION_LIMIT } from '../../../../constants/pages'
import {
    TransactionsHistoryHeaderRow,
    TransactionsHistoryInfoRow,
} from './transactions-history-rows'

const TransactionHistory = () => {
    const { data, loading } = useQuery(POOL_TRANSACTIONS_QUERY)
    const { renderList, totalPages, page, changePage } = usePagination(
        data?.poolTransactions,
        loading,
        PAGINATION_LIMIT
    )

    return (
        <AutoColumn gap="xl">
            <InfoSection>
                <TransactionsHistoryHeaderRow />
                {!loading &&
                    renderList?.length !== 0 &&
                    renderList?.map((tx) => (
                        <TransactionsHistoryInfoRow key={tx.id} data={tx} />
                    ))}
            </InfoSection>
            <Bottom>
                <Pagination
                    page={page}
                    setPage={changePage}
                    totalPages={totalPages}
                />
            </Bottom>
        </AutoColumn>
    )
}

export default TransactionHistory
