export const BIDE_ABI= [
    {
      "inputs": [],
      "name": "InvalidInitialization",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "NotInitializing",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "OwnableInvalidOwner",
      "type": "error"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "OwnableUnauthorizedAccount",
      "type": "error"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint64",
          "name": "version",
          "type": "uint64"
        }
      ],
      "name": "Initialized",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "previousOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "address payable",
          "name": "traderAddress",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "assetIn",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "assetOut",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "refundETH",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "refundToken",
          "type": "uint256"
        }
      ],
      "name": "logOrderCancelled",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "enum bidelityLimitOrder.OrderState",
          "name": "orderState",
          "type": "uint8"
        },
        {
          "indexed": false,
          "internalType": "enum bidelityLimitOrder.OrderType",
          "name": "orderType",
          "type": "uint8"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "traderAddress",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "assetIn",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "assetOut",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "assetInOffered",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "assetOutExpected",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "executorFee",
          "type": "uint256"
        }
      ],
      "name": "logOrderCreated",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "executor",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "logOrderExecuted",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "EXECUTOR_FEE",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "MAXPOSITIONS",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "STAKE_FEE",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "ethValue",
          "type": "uint256"
        }
      ],
      "name": "calculatePaymentETH",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "valueEth",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "stake",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "executorFee",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "total",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "orderId",
          "type": "uint256"
        }
      ],
      "name": "cancelOrder",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "components": [
            {
              "internalType": "enum bidelityLimitOrder.OrderType",
              "name": "orderType",
              "type": "uint8"
            },
            {
              "internalType": "address",
              "name": "assetIn",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "assetOut",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "assetInOffered",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "assetOutExpected",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "price",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "slippage",
              "type": "uint256"
            },
            {
              "internalType": "address[]",
              "name": "path",
              "type": "address[]"
            },
            {
              "internalType": "uint256",
              "name": "executorFee",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expire",
              "type": "uint256"
            }
          ],
          "internalType": "struct bidelityLimitOrder.Limit",
          "name": "limit_info",
          "type": "tuple"
        }
      ],
      "name": "createOrder",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "offset",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "size",
          "type": "uint256"
        }
      ],
      "name": "executeOrders",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "executor",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "offset",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "size",
          "type": "uint256"
        }
      ],
      "name": "getInsight",
      "outputs": [
        {
          "components": [
            {
              "internalType": "address",
              "name": "addressA",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "addressB",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "decimalsA",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "decimalsB",
              "type": "uint256"
            },
            {
              "internalType": "bool",
              "name": "locked",
              "type": "bool"
            },
            {
              "internalType": "uint256",
              "name": "totalOrders",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "totalTraders",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "commissionA",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "commissionB",
              "type": "uint256"
            }
          ],
          "internalType": "struct bidelityLimitOrder.Insight[]",
          "name": "",
          "type": "tuple[]"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "offset",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "size",
          "type": "uint256"
        }
      ],
      "name": "getOpenOrders",
      "outputs": [
        {
          "components": [
            {
              "internalType": "enum bidelityLimitOrder.OrderState",
              "name": "orderState",
              "type": "uint8"
            },
            {
              "internalType": "enum bidelityLimitOrder.OrderType",
              "name": "orderType",
              "type": "uint8"
            },
            {
              "internalType": "address payable",
              "name": "traderAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "assetIn",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "assetOut",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "assetInOffered",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "assetOutExpected",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "executorFee",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "stake",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "ordersI",
              "type": "uint256"
            },
            {
              "internalType": "address[]",
              "name": "path",
              "type": "address[]"
            },
            {
              "internalType": "uint256",
              "name": "price",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "slippage",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expire",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "inDecimals",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "outDecimals",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "created",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "updated",
              "type": "uint256"
            }
          ],
          "internalType": "struct bidelityLimitOrder.Order[]",
          "name": "",
          "type": "tuple[]"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "index",
          "type": "uint256"
        }
      ],
      "name": "getOrderIdForAddress",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "offset",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "size",
          "type": "uint256"
        }
      ],
      "name": "getOrders",
      "outputs": [
        {
          "components": [
            {
              "internalType": "enum bidelityLimitOrder.OrderState",
              "name": "orderState",
              "type": "uint8"
            },
            {
              "internalType": "enum bidelityLimitOrder.OrderType",
              "name": "orderType",
              "type": "uint8"
            },
            {
              "internalType": "address payable",
              "name": "traderAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "assetIn",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "assetOut",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "assetInOffered",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "assetOutExpected",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "executorFee",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "stake",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "ordersI",
              "type": "uint256"
            },
            {
              "internalType": "address[]",
              "name": "path",
              "type": "address[]"
            },
            {
              "internalType": "uint256",
              "name": "price",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "slippage",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expire",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "inDecimals",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "outDecimals",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "created",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "updated",
              "type": "uint256"
            }
          ],
          "internalType": "struct bidelityLimitOrder.Order[]",
          "name": "",
          "type": "tuple[]"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "offset",
          "type": "uint256"
        }
      ],
      "name": "getOrdersForAddress",
      "outputs": [
        {
          "components": [
            {
              "internalType": "enum bidelityLimitOrder.OrderState",
              "name": "orderState",
              "type": "uint8"
            },
            {
              "internalType": "enum bidelityLimitOrder.OrderType",
              "name": "orderType",
              "type": "uint8"
            },
            {
              "internalType": "address payable",
              "name": "traderAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "assetIn",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "assetOut",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "assetInOffered",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "assetOutExpected",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "executorFee",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "stake",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "ordersI",
              "type": "uint256"
            },
            {
              "internalType": "address[]",
              "name": "path",
              "type": "address[]"
            },
            {
              "internalType": "uint256",
              "name": "price",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "slippage",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expire",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "inDecimals",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "outDecimals",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "created",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "updated",
              "type": "uint256"
            }
          ],
          "internalType": "struct bidelityLimitOrder.Order[]",
          "name": "",
          "type": "tuple[]"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        }
      ],
      "name": "getOrdersForAddressLength",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getOrdersLength",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "contract IUniswapRouter02",
          "name": "_uniswapRouter02",
          "type": "address"
        }
      ],
      "name": "initialize",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "insightId",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "name": "insightInfo",
      "outputs": [
        {
          "internalType": "address",
          "name": "addressA",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "addressB",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "decimalsA",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "decimalsB",
          "type": "uint256"
        },
        {
          "internalType": "bool",
          "name": "locked",
          "type": "bool"
        },
        {
          "internalType": "uint256",
          "name": "totalOrders",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "totalTraders",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "commissionA",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "commissionB",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "offset",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "size",
          "type": "uint256"
        }
      ],
      "name": "needUpdate",
      "outputs": [
        {
          "internalType": "bool",
          "name": "need",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "orderBook",
      "outputs": [
        {
          "internalType": "enum bidelityLimitOrder.OrderState",
          "name": "orderState",
          "type": "uint8"
        },
        {
          "internalType": "enum bidelityLimitOrder.OrderType",
          "name": "orderType",
          "type": "uint8"
        },
        {
          "internalType": "address payable",
          "name": "traderAddress",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "assetIn",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "assetOut",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "assetInOffered",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "assetOutExpected",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "executorFee",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "stake",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "ordersI",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "price",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "slippage",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "expire",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "inDecimals",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "outDecimals",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "created",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "updated",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "orders",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "ordersNum",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "payout",
      "outputs": [
        {
          "internalType": "bool",
          "name": "res",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_EXECUTOR_FEE",
          "type": "uint256"
        }
      ],
      "name": "setNewExecutorFee",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_stakeAddress",
          "type": "address"
        }
      ],
      "name": "setNewStakeAddress",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_STAKE_FEE",
          "type": "uint256"
        }
      ],
      "name": "setNewStakeFee",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "contract IUniswapRouter02",
          "name": "_uniswapRouter02",
          "type": "address"
        }
      ],
      "name": "setUniswapRouter",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "stakeAddress",
      "outputs": [
        {
          "internalType": "address payable",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "uniswapRouter02",
      "outputs": [
        {
          "internalType": "contract IUniswapRouter02",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "key",
          "type": "bytes32"
        },
        {
          "internalType": "bool",
          "name": "locked",
          "type": "bool"
        }
      ],
      "name": "updateUnlock",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_executor",
          "type": "address"
        }
      ],
      "name": "update_executor",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_token",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        }
      ],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "stateMutability": "payable",
      "type": "receive"
    }
  ]