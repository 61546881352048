import React from 'react'
import { useQuery } from '@apollo/client'
import Pagination from '../../../../components/Pagination'
import { AutoColumn } from '../../../../components/styled'
import { Bottom, InfoSection } from '../../../../components/StyledSection'
import { PAIRS_INFO_QUERY } from '../../query'
import { PAGINATION_LIMIT_TWELVE } from '../../../../constants/pages'
import { usePagination } from '../../../../hooks/usePagination'
import { InfoRow, TitleRow } from './pairs-rows'

const Pairs = () => {
    const { data, loading } = useQuery(PAIRS_INFO_QUERY)

    const { renderList, totalPages, page, changePage } = usePagination(
        data?.pairs,
        loading,
        PAGINATION_LIMIT_TWELVE
    )

    return (
        <AutoColumn gap="xl">
            <InfoSection>
                <TitleRow />
                {!loading &&
                    renderList?.length !== 0 &&
                    renderList?.map((item) => (
                        <InfoRow key={item.id} data={item} />
                    ))}
            </InfoSection>
            <Bottom>
                <Pagination
                    page={page}
                    setPage={changePage}
                    totalPages={totalPages}
                />
            </Bottom>
        </AutoColumn>
    )
}

export default Pairs
